<template>
  <div class="w-full grid sm:grid-cols-[repeat(auto-fill,minmax(15rem,1fr))] grid-cols-2">
    <NuxtLink :id="`project_${ _project.project_id }`" class="w-full" v-for="_project in projects?.completed?.projects" :to="`/app/project/${_project.project_id}`">
            <button  class="w-full border-white/10 border-[0.5px] aspect-square duration-500 flex flex-col justify-center items-center relative overflow-hidden group" :aria-label="_project._project_name">
                <LazyNuxtImg loading="lazy" quality="5" width="50" height="50" class="pointer-events-none w-full h-full object-cover blur-xl opacity-50 brightness-50 scale-125" :src="`${_project.project_logo}?v=123`" :alt="`${_project.project_name} Logo`" />
  
                  <p class="text-3xl absolute text-outline uppercase top-4 right-4" >{{ _project?.project_ath }}</p>
  
  
                  <div class="absolute justify-center items-center flex flex-col break-all w-full -translate-y-4 sm:translate-y-0">
                    <LazyNuxtImg loading="lazy" quality="90" width="100" height="100"  class="pointer-events-none sm:w-[6rem] sm:h-[6rem] h-12 w-12 object-cover rounded-xl  group-hover:scale-110 duration-300" :src="`${_project.project_logo}?v=123`" :alt="`${_project.project_name} Logo`" />
                    <p class="pt-2 text-white w-3/4" >{{ _project.project_name }}</p>
                  </div>
  
                  <div class="w-full text-sm text-left p-2 opacity-20 group-hover:opacity-100 duration-300 absolute left-0 bottom-0 sm:leading-normal leading-4">
  
                    <p class="col-span-full pb-2 text-white" >Allocation</p>
  
                    <div v-if="_project?.project_tier_allocation?.tier1_min > 0" class="flex justify-start items-center w-full space-x-4">
                      <p>Tier 1</p>
                    <div class="sm:text-xs text-3xs">
                      <p v-if="_project?.project_tier_allocation?.tier1_min == 0 && _project?.project_tier_allocation?.tier1_max == 0">-</p>
                      <p class="" >{{ _project?.project_tier_allocation?.tier1_min }} {{ _project?.project_tier_allocation?.currency }} - {{ _project?.project_tier_allocation?.tier1_max }} {{ _project?.project_tier_allocation?.currency }}</p>
                    </div>
                    </div>
  
  
                    <div v-if="_project?.project_tier_allocation?.tier2_min > 0" class="flex justify-start items-center w-full space-x-4">
                      <p>Tier 2</p>
                    <div class="sm:text-xs text-3xs">
                      <p v-if="_project?.project_tier_allocation?.tier2_min == 0 && _project?.project_tier_allocation?.tier2_max == 0">-</p>
                      <p class="" >{{ _project?.project_tier_allocation?.tier2_min }} {{ _project?.project_tier_allocation?.currency }} - {{ _project?.project_tier_allocation?.tier2_max }} {{ _project?.project_tier_allocation?.currency }}</p>
                    </div>
                    </div>
  
  
                    <div v-if="_project?.project_tier_allocation?.tier3_min  > 0" class="flex justify-start items-center w-full space-x-4">
                      <p>Tier 3</p>
                    <div class="sm:text-xs text-3xs">
                      <p v-if="_project?.project_tier_allocation?.tier3_min == 0 && _project?.project_tier_allocation?.tier3_max == 0">-</p>
                      <p class="" >{{ _project?.project_tier_allocation?.tier3_min }} {{ _project?.project_tier_allocation?.currency }} - {{ _project?.project_tier_allocation?.tier3_max }} {{ _project?.project_tier_allocation?.currency }}</p>
                    </div>
                    </div>
  
                  </div>
  
  
              </button>
          </NuxtLink>
  </div>
</template>

<script setup>

  const props = defineProps(["projects"]);
  const { projects } = toRefs(props);

</script>

<style scoped>

.text-outline {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff50;
  color: #00000000;
}
</style>